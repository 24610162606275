<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ $t("orderChange.orderChange") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`orderChange.close`)"
            v-on:click="$emit('update:show', false)"
          ></button>
        </div>
        <div class="modal-body" v-if="!paymentPrice">
          <div
            class="text-start m-2 custom-input"
            v-if="removeElement !== undefined && removeElement !== false"
          >
            <h2 class="bold-24 my-3">{{ $t("orderChange.removeItems") }}</h2>
            <div
              class="row custom-input whitebg-input"
              v-for="p in removeProducts"
              :key="p.id"
            >
              <div class="col-auto">
                <label class="form-label bold-12 text-gray">{{
                  $t("orderAfterStatus.return")
                }}</label>
                <label class="cstm-check mt-3 mb-4">
                  <input
                    type="checkbox"
                    :value="true"
                    v-model="p.return"
                    v-on:change="calcHasReturn"
                    :disabled="
                      p?.sent_out_quantity === 0 ||
                      p?.need_to_sent_quantity === 0
                    "
                  />
                  <span class="checkmark radio"></span>
                </label>
              </div>
              <div class="col-3">
                <label class="form-label bold-12 text-gray">{{
                  $t("orderChange.quantity")
                }}</label>
                <input
                  type="number"
                  v-model="p.quantity"
                  class="form-control"
                  :max="
                    p?.return ? p?.sent_out_quantity : p?.need_to_sent_quantity
                  "
                  min="1"
                />
              </div>
              <div class="col">
                <div class="mb-3">
                  <label class="form-label bold-12 text-gray">{{
                    $t("orderChange.product")
                  }}</label>
                  <v-select
                    :options="products.filter((e) => e.quantity > 0)"
                    v-model="p.id"
                    :filterable="false"
                    :placeholder="$t(`orderChange.selectProduct`)"
                    append-to-body
                    :reduce="(c) => c.variant_id"
                    v-on:option:selected="
                      (e) => changeProducts(e, e.variant_id, true)
                    "
                    v-on:option:deselected="() => changeProducts(0, 0, true)"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching && search.length > 3">
                        {{ $t("orderChange.noProductsFoundFor") }}
                        <em>{{ search }}</em
                        >.
                      </template>
                      <span v-else>{{
                        $t("orderChange.startTypingToSearchForAProduct")
                      }}</span>
                    </template>
                    <template v-slot:selected-option="option">
                      <div class="row">
                        <div class="col-auto px-0">
                          <img
                            :src="mediaServer + '/' + option?.image"
                            v-if="
                              option?.image !== null &&
                              option?.image !== undefined
                            "
                            class="product-image"
                            alt=""
                          />
                          <BaseIcon
                            v-else
                            name="no-image"
                            class="product-image"
                          />
                        </div>
                        <div class="col">
                          <div>
                            <b>{{ option.name }}</b>
                          </div>
                          <div>
                            <span
                              v-for="char in option.caracteristics"
                              :key="char"
                              class="me-2"
                            >
                              {{ char }}
                            </span>
                            <span class="me-2">
                              {{ option.serial }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-slot:option="option">
                      <span class="me-2">
                        {{ option.serial }}
                      </span>
                      <span
                        v-for="char in option.caracteristics"
                        :key="char"
                        class="me-2"
                      >
                        {{ char }}
                      </span>
                      <b>{{ option.name }}</b>
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
          </div>
          <div class="text-start m-2 custom-input" v-if="addElement">
            <h2 class="bold-24 my-3">{{ $t("orderChange.addItems") }}</h2>
            <div
              class="row custom-input whitebg-input"
              v-for="p in addProducts"
              :key="p.id"
            >
              <div class="col-3">
                <label class="form-label bold-12 text-gray">{{
                  $t("orderChange.quantity")
                }}</label>
                <input
                  type="number"
                  v-model="p.quantity"
                  class="form-control"
                  min="1"
                />
              </div>
              <div class="col-9">
                <div class="mb-3">
                  <label class="form-label bold-12 text-gray">{{
                    $t("orderChange.product")
                  }}</label>
                  <v-select
                    v-model:options="p.products"
                    v-on:search="(s, k) => fetchProducts(s, k, p)"
                    v-model="p.id"
                    :filterable="false"
                    append-to-body
                    :placeholder="$t(`orderChange.selectProduct`)"
                    :reduce="(c) => c.variant_id"
                    v-on:option:selected="
                      (e) => changeProducts(e, e.variant_id)
                    "
                    v-on:option:deselected="() => changeProducts(0, 0)"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching && search.length > 3">
                        {{ $t("orderChange.noProductsFoundFor") }}
                        <em>{{ search }}</em
                        >.
                      </template>
                      <span v-else>{{
                        $t("orderChange.startTypingToSearchForAProduct")
                      }}</span>
                    </template>
                    <template v-slot:selected-option="option">
                      <div class="row">
                        <div class="col-auto px-0">
                          <img
                            :src="mediaServer + '/' + option?.image"
                            v-if="
                              option?.image !== null &&
                              option?.image !== undefined
                            "
                            class="product-image"
                            alt=""
                          />
                          <BaseIcon
                            v-else
                            name="no-image"
                            class="product-image"
                          />
                        </div>
                        <div class="col">
                          <div>
                            <b>{{ option.name }}</b>
                          </div>
                          <div>
                            <span
                              v-for="char in option.caracteristics"
                              :key="char"
                              class="me-2"
                            >
                              {{ char }}
                            </span>
                            <span class="me-2">
                              {{ option.serial }}
                            </span>
                          </div>
                        </div>
                        <div class="col-auto">
                          <div>{{ option.available_quantity }} pcs</div>
                          <div>{{ getPrice(option) }} {{ currency }}</div>
                        </div>
                      </div>
                    </template>
                    <template v-slot:option="option">
                      <span class="me-2">
                        {{ option.serial }}
                      </span>
                      <span
                        v-for="char in option.caracteristics"
                        :key="char"
                        class="me-2"
                      >
                        {{ char }}
                      </span>
                      <b>{{ option.name }}</b>
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row custom-input whitebg-input text-start"
            v-if="
              webshopId !== null &&
              (hasReturn || deliveryStatus > 1) &&
              !addElement
            "
          >
            <label class="cstm-check dropdown-item">
              <span class="text-black mx-3 regular-14">{{
                $t("orderChange.selfDeliveryNoAutomaticDeliveryNeeded")
              }}</span>
              <input type="checkbox" v-model="noDelivery" :value="true" /><span
                class="checkmark mx-3"
              ></span>
            </label>
          </div>
          <div class="custom-input whitebg-input" v-if="addElement">
            <div class="row">
              <div class="col-3 mb-2">
                <label class="form-label bold-12 text-gray">{{
                  $t("orderChange.discount2")
                }}</label>
                <div class="fix-end-input">
                  <input
                    type="number"
                    class="form-control"
                    v-model="discount"
                    min="0"
                    max="100"
                  />
                  <span>%</span>
                </div>
              </div>
            </div>
            <div class="row">
              <label class="cstm-check dropdown-item">
                <span class="text-black mx-2 regular-14">{{
                  $t("newSale.preorder")
                }}</span>
                <input type="checkbox" v-model="preorder" /><span
                  class="checkmark mx-2"
                ></span>
              </label>
            </div>
          </div>
          <div
            class="text-start m-2 custom-input whitebg-input"
            v-if="
              webshopId !== null &&
              !noDelivery &&
              (hasReturn || deliveryStatus > 1) &&
              webshops.length > 0
            "
          >
            <h2 class="bold-24 my-3">
              {{ $t("orderChange.deliveryAddress") }}
            </h2>
            <div class="row">
              <div class="col-12 mb-3">
                <label class="form-label bold-12 text-gray">{{
                  $t("orderChange.country")
                }}</label>
                <v-select
                  :options="countries"
                  v-model="delivery.country_id"
                  label="name"
                  :placeholder="$t(`orderChange.selectCountry`)"
                  :reduce="(c) => c.id"
                />
              </div>
              <div class="col-12" v-if="delivery.country_id > 0">
                <label class="form-label bold-12 text-gray">
                  {{ $t("order.delivery2") }}
                </label>
                <v-select
                  :options="
                    webshops
                      .find((e) => e.id === webshopId)
                      .deliveryMethods.filter(
                        (e) =>
                          e.supported_coutries.indexOf(
                            countries.find((f) => f.id === delivery.country_id)
                              ?.code
                          ) > -1
                      )
                  "
                  v-model="delivery.method"
                  label="name"
                  :placeholder="$t(`orderChange.selectDelivery`)"
                  :reduce="(c) => c.id"
                />
              </div>
              <div
                class="col md-3"
                v-if="selectedDelivery?.type === 2 && addProducts?.length > 1"
              >
                <label class="form-label bold-12 text-gray">{{
                  $t("companies.postCode")
                }}</label>
                <input class="form-control" v-model="packagePointPostCode" />
              </div>
              <div
                class="col-auto md-3 mt-4 pt-3"
                v-if="selectedDelivery?.type === 2 && addProducts?.length > 1"
              >
                <button
                  class="btn btn-primary"
                  v-on:click="searchPackagePoints"
                >
                  Search
                </button>
              </div>
              <div
                class="col-12 md-3 mt-2"
                v-if="selectedDelivery?.type === 2 && addProducts?.length > 1"
              >
                <v-select
                  :options="packagePoints"
                  v-model="delivery.package_point"
                  :placeholder="$t(`newSale.selectPackagePoint`)"
                  :filter="filterPackagePoint"
                  :reduce="(c) => c.pointId"
                >
                  <template v-slot:selected-option="option">
                    <div class="row">
                      <div class="col">
                        <b>{{ option.name }}</b>
                      </div>
                      <div class="col-auto">
                        {{ option.openingTime }}
                      </div>
                      <div class="col-12">
                        {{ option.postCode }} {{ option.city }}
                        {{ option.street }}
                      </div>
                    </div>
                  </template>
                  <template v-slot:option="option">
                    <div class="row text-wrap">
                      <div class="col">
                        <b>{{ option.name }}</b>
                      </div>
                      <div class="col-auto">
                        {{ option.openingTime }}
                      </div>
                      <div class="col-12">
                        {{ option.postCode }} {{ option.city }}
                        {{ option.street }}
                      </div>
                    </div>
                  </template>
                </v-select>
              </div>
              <div
                class="col-5 mb-3"
                v-if="
                  selectedDeliveryCountry?.need_post_code &&
                  (selectedDelivery?.type === 1 || selectedDelivery?.type === 5)
                "
              >
                <label class="form-label bold-12 text-gray">{{
                  $t("orderChange.postCode")
                }}</label>
                <input
                  class="form-control"
                  v-on:change="searchPostCode(false)"
                  v-model="delivery.post_code"
                />
              </div>
              <div
                class="col-5 mb-3"
                v-if="
                  selectedDeliveryCountry?.need_state &&
                  (selectedDelivery?.type === 1 || selectedDelivery?.type === 5)
                "
              >
                <label class="form-label bold-12 text-gray">{{
                  stateTypes[setNullToZero(selectedDeliveryCountry?.state_type)]
                    ?.name
                }}</label>
                <input class="form-control" v-model="delivery.state" />
              </div>
              <div
                class="col-7 mb-3"
                v-if="
                  selectedDelivery?.type === 1 || selectedDelivery?.type === 5
                "
              >
                <label class="form-label bold-12 text-gray">{{
                  $t("orderChange.city")
                }}</label>
                <input class="form-control" v-model="delivery.city" />
              </div>
              <div
                class="col-5 mb-3"
                v-if="
                  selectedDeliveryCountry?.need_area &&
                  (selectedDelivery?.type === 1 || selectedDelivery?.type === 5)
                "
              >
                <label class="form-label bold-12 text-gray">{{
                  $t("newSale.area")
                }}</label>
                <input class="form-control" v-model="delivery.area" />
              </div>
              <div
                class="col-9 mb-3"
                v-if="
                  selectedDelivery?.type === 1 || selectedDelivery?.type === 5
                "
              >
                <label class="form-label bold-12 text-gray">{{
                  $t("order.address")
                }}</label>
                <input class="form-control" v-model="delivery.street" />
              </div>
              <div
                class="col-3 mb-3"
                v-if="
                  selectedDelivery?.type === 1 || selectedDelivery?.type === 5
                "
              >
                <label class="form-label bold-12 text-gray">{{
                  $t("orderChange.number2")
                }}</label>
                <input class="form-control" v-model="delivery.house" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-body" v-else>
          <Payment
            v-model:total="paymentPrice"
            v-model:selected-payment="selectedPayment"
            v-model:payed="payed"
            v-model:need="totalNeedToPay"
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-primary"
            v-on:click="showAdd"
            v-if="!addElement && !paymentPrice"
          >
            {{ $t("orderChange.addItems") }}
          </button>
          <button
            class="btn btn-primary"
            v-if="paymentPrice"
            v-on:click="savePayment"
            :class="{ 'loading-spin': loadAddBtn }"
            :disabled="loadAddBtn"
          >
            <span>{{ $t("orderChange.save") }}</span>
          </button>
          <div v-else-if="shop">
            <button
              class="btn ms-2"
              :class="
                payment.type === 5 || payment.type === 3
                  ? 'btn-type-2'
                  : 'btn-type-4'
              "
              v-show="payment.type !== 4 || shop?.iban !== null"
              v-on:click="nextToPayment(payment)"
              v-for="payment in shop?.paymentMethods"
              :key="payment.id"
              :disabled="loadAddBtn"
            >
              <span>{{ payment.name }}</span>
            </button>
          </div>
          <button
            v-else
            type="button"
            class="btn btn-primary"
            v-on:click="addNewItems"
            :class="{ 'loading-spin': loadAddBtn }"
            :disabled="loadAddBtn"
          >
            {{ $t("orderChange.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../modules/http";
import { useStore } from "vuex";
import BaseIcon from "../icons/BaseIcon";
import Payment from "../Pos/Payment";
import StateTypes from "../../modules/StateTypes";
const bootstrap = require("bootstrap");

export default {
  name: "OrderChange",
  props: {
    modelValue: Number,
    removeElement: Object,
    products: Object,
    defaultDelivery: Object,
    webshopId: Number,
    shopId: Number,
    warehouse: Number,
    deliveryStatus: Number,
    addElement: Boolean,
    currency: String,
  },
  emits: ["", "newItem", "update:addElement"],
  data() {
    return {
      store: useStore(),
      hasReturn: false,
      myModal: undefined,
      success: false,
      loadAddBtn: false,
      noDelivery: false,
      countries: [],
      webshops: [],
      shop: undefined,
      delivery: {},
      stateTypes: StateTypes,
      discount: 0,
      addProducts: [{ id: null, quantity: 1, products: [] }],
      removeProducts: [{ id: null, quantity: 1 }],
      selectedPayment: null,
      paymentPrice: null,
      payed: 0,
      totalNeedToPay: 0,
      preorder: false,
      packagePointPostCode: "",
      packagePoints: [],
    };
  },
  computed: {
    warehouses() {
      return this.store.state.warehouses;
    },
    selectedWarehouse() {
      return this.warehouses.find((e) => e.id === this.warehouse);
    },
    mediaServer() {
      return process.env.VUE_APP_MEDIA_URL;
    },
    selectedDelivery() {
      return this.webshops
        .find((e) => e.id === this.webshopId)
        ?.deliveryMethods?.find((e) => e.id === this.delivery?.method);
    },
    selectedDeliveryCountry() {
      return this.countries.find((e) => e.id === this.delivery?.country_id);
    },
  },
  mounted() {
    if (this.defaultDelivery !== undefined && this.defaultDelivery !== null) {
      this.delivery.country_id = this.defaultDelivery.country_id;
      this.delivery.city = this.defaultDelivery.city;
      this.delivery.post_code = this.defaultDelivery.post_code;
      this.delivery.street = this.defaultDelivery.street;
      this.delivery.house = this.defaultDelivery.house;
    }
  },
  watch: {
    defaultDelivery() {
      this.delivery.country_id = this.defaultDelivery.country_id;
      this.delivery.city = this.defaultDelivery.city;
      this.delivery.post_code = this.defaultDelivery.post_code;
      this.delivery.street = this.defaultDelivery.street;
      this.delivery.house = this.defaultDelivery.house;
    },
    removeElement() {
      if (typeof this.removeElement === "boolean") {
        return;
      }
      this.removeProducts.push({
        id: null,
        quantity: 1,
      });
      this.removeProducts.push({
        id: this.removeElement.variant_id,
        quantity: 1,
      });
      this.changeProducts(
        this.removeElement,
        this.removeElement.variant_id,
        true
      );
      this.calcHasReturn();
      this.removeProducts.splice(0, 1);
    },
  },
  methods: {
    setNullToZero(val) {
      if (val === null) {
        return 0;
      }
      return val;
    },
    showAdd() {
      this.$emit("update:addElement", true);
    },
    getPrice(product) {
      if (
        product?.discount_price !== null &&
        product?.discount_price !== undefined
      ) {
        return Math.round(product?.discount_price * 100) / 100;
      }
      if (product?.sell_price > 0) {
        return Math.round(product?.sell_price * 100) / 100;
      }
      return product?.sell_price;
    },
    loadAddress() {
      if (this.hasReturn || this.deliveryStatus > 1) {
        if (this.countries.length === 0) {
          http.fetch("/countries/all").then((data) => {
            this.countries = data;
          });
        }
        if (this.webshops.length === 0) {
          http.fetch("/webshops").then((data) => {
            this.webshops = data;
          });
        }
      }
    },
    calcHasReturn() {
      this.hasReturn = this.removeProducts.findIndex((e) => e.return) > -1;
      this.loadAddress();
    },
    showModal() {
      if (this.myModal === undefined) {
        this.myModal = new bootstrap.Modal(this.$refs.modal, {
          backdrop: "static",
        });
      }
      this.myModal.show();
      this.loadAddress();

      if (
        this.shopId &&
        (this.shop === undefined || this.shop?.id !== this.shopId)
      ) {
        http.fetch("/shops/" + this.shopId).then((data) => {
          this.shop = data;
        });
      }
    },
    barcodeReaded(code) {
      let send = {
        q: code,
        language: this.$i18n.locale,
      };
      if (this.currency !== null && this.currency !== undefined) {
        send.currency = this.currency;
      }
      if (this.warehouse > 0) {
        send.warehouse_id = this.warehouse;
      }
      http.fetch("/products/products/search", send).then((data) => {
        if (data.length > 0) {
          this.addProducts[0].products = data;
          this.addProducts[0].id = data[0].variant_id;
          this.changeProducts(data[0].variant_id);
        }
      });
    },
    changeProducts(item, variant_id, remove = false) {
      let hasNull = false;
      let quantity = 0;
      let count = 0;
      let forData = remove ? this.removeProducts : this.addProducts;
      let row = null;

      for (const p in forData) {
        if (forData[p].id === null) {
          if (p !== 0) {
            forData.splice(p, 1);
          } else {
            hasNull = true;
          }
        } else if (forData[p].id === variant_id) {
          if (row === null) {
            row = forData[p];
          }
          count++;
          quantity += forData[p].quantity;
          if (count > 1) {
            forData.splice(p, 1);
          }
        }
      }
      if (count === 0) {
        return;
      }

      if (count > 1) {
        row.quantity = quantity;
      }

      if (remove) {
        row.return = item?.need_to_sent_quantity === 0;
        row.sent_out_quantity = item?.sent_out_quantity;
        row.need_to_sent_quantity = item?.need_to_sent_quantity;
        if (!row.return) {
          row.quantity = item.need_to_sent_quantity;
        } else {
          row.quantity = item.sent_out_quantity;
        }
      }

      console.log("hasNUll");
      console.log(hasNull);

      if (!hasNull) {
        forData.unshift({
          id: null,
          quantity: 1,
          products: [],
        });
      }
    },
    nextToPayment(payment) {
      this.selectedPayment = payment;
      this.addNewItems();
    },
    savePayment() {
      this.loadAddBtn = true;
      let change = {
        order_id: this.modelValue,
        payment_method: this.selectedPayment.id,
        amount: this.totalNeedToPay,
      };
      http
        .fetch("/orders/payed", change, true)
        .then((data) => {
          if (data.receipt) {
            this.printReceipt(data.receipt);
          }
          this.myModal.hide();
          this.loadAddBtn = false;
        })
        .catch(() => {
          this.loadAddBtn = false;
        });
    },
    printReceipt(receipt) {
      if (this.shop?.cassa_endpoint) {
        fetch(this.shop.cassa_endpoint, {
          method: "POST",
          body: receipt,
        });
      }
    },
    addNewItems() {
      this.loadAddBtn = true;
      let change = {
        order_id: this.modelValue,
      };
      change.add_items = [];
      change.remove_items = [];

      for (const product of this.addProducts) {
        if (product.id !== null && product.id !== undefined) {
          change.add_items.unshift({
            id: product.id,
            quantity: product.quantity,
          });
        }
      }

      for (const product of this.removeProducts) {
        if (product.id !== null && product.id !== undefined) {
          change.remove_items.unshift({
            id: product.id,
            quantity: product.quantity,
          });
        }
      }

      if (this.hasReturn || this.deliveryStatus > 1) {
        change.delivery = this.delivery;
      }

      change.discount = this.discount;
      change.no_delivery = this.noDelivery ? 1 : 0;
      change.preorder = this.preorder ? 1 : 0;

      http
        .fetch("/orders/change", change, true)
        .then((data) => {
          this.$emit("newItem", data);
          if (!this.selectedPayment) {
            this.myModal.hide();
          } else {
            this.paymentPrice = data.remaining_price;
          }
          this.$emit("update:show", false);
          this.loadAddBtn = false;
          this.addProducts = [{ id: null, quantity: 1, products: [] }];
          this.removeProducts = [{ id: null, quantity: 1 }];
        })
        .catch(() => {
          this.loadAddBtn = false;
        });
    },
    fetchProducts(search, loading, p) {
      if (search.length < 3) {
        return;
      }
      loading(true);
      let send = {
        q: search,
        language: this.$i18n.locale,
      };
      if (this.currency !== null && this.currency !== undefined) {
        send.currency = this.currency;
      }
      if (this.warehouse > 0) {
        send.warehouse_id = this.warehouse;
      }
      http
        .fetch("/products/products/search", send)
        .then((data) => {
          p.products = data;
          loading(false);
        })
        .catch(() => {
          loading(false);
        });
    },
    filterPackagePoint(options, search) {
      return search.length
        ? options.filter(
            (e) =>
              e.id?.toLowerCase()?.indexOf(search.toLowerCase()) > -1 ||
              e.name?.toLowerCase()?.indexOf(search.toLowerCase()) > -1 ||
              e.city?.toLowerCase()?.indexOf(search.toLowerCase()) > -1 ||
              e.postCode?.indexOf(search) === 0
          )
        : options;
    },
    searchPackagePoints() {
      this.packagePoints = [];
      http
        .fetch(
          "/deliveries/package-points/" +
            this.selectedDelivery.id +
            "/" +
            this.selectedDeliveryCountry.code +
            "/" +
            this.packagePointPostCode
        )
        .then((data) => {
          this.packagePoints = data.list;
        });
    },
  },
  components: { Payment, BaseIcon },
};
</script>
